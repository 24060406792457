import React, { useContext, useState, useEffect } from "react";
import { PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import {
  SEO,
  NameInput,
  RutInput,
  GenderSelector,
  NationalitySelector,
  ButtonPrimary,
} from "@components";
import { Edit } from "images/Goal";
import { LayoutBildRegistration } from "@components/Structural/RegistrationStep1";
import { AppContext, MissionsContext, ProfileContext } from "@context";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { LayoutNotMissionDesktop, LayoutNotMissionMobile } from "@components";
import {
  UserInformationComponent,
  MoreUserInformation,
  ConfirmIdentity,
  ChooseInvestorProfile,
  BankDataComponent,
} from "@components";
import Mision2Mobile from "@components/Structural/MissionsMobile/Mision2";
import { useAuth, useForm } from "@hooks";
import { updateUser, getCompanies } from "@apollo";
import { useApolloClient } from "@apollo/client";
import { searchUserCompany } from "@utils";

const UserInformationPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const [form, , , handleChange] = useForm({});
  const [step, setStep] = useState<number>(0);
  const [title, setTitle] = useState<string>("Tus datos personales");
  const [subtitle, setSubtitle] = useState<string>("Confirma o ingresa la información necesaria");
  const [buttonText, setButtonText] = useState<string>("Siguiente");
  const client = useApolloClient();
  const { companies } = getCompanies();

  const handleClick = () => {
    if (step == 3) {
      try {
        // console.log("🚀 « form", form)
        updateUser(form, true, client)
      } catch (error) {
        console.log(error)
      }
      navigate(Route.investments)
    } else {
      setStep(step + 1)
    }
  }

  const handleBack = () => {
    if (step == 0) {
      navigate(Route.investments)
    } else {
      setStep(step - 1)
    }
  }

  useEffect(() => {
    if (step == 0) {
      setTitle("Tus datos personales")
      setSubtitle("Confirma o ingresa la información necesaria")
      setButtonText("Siguiente")
    } else if (step == 1) {
      setTitle("Algunos datos más")
    } else if (step == 2) {
      setTitle("Elige tu perfil de inversión")
      setSubtitle("Selecciona el perfil que más te represente")
      setButtonText("Siguiente")
    } else if (step == 3) {
      setTitle("Confirma tu identidad")
      setSubtitle("Sube 2 fotos con buena iluminación de tu Cédula de Identidad y responde el cuestionario")
      setButtonText("Terminar")
    }
  }, [step])

  const isDiscoverOn = () => {
    setTitle("Cuéntanos un poco de ti")
    setSubtitle("Así podrás descubrir inversiones que se ajusten a tus objetivos")
  }

  useEffect(() => {
    const hasCompany = searchUserCompany(form.rut, companies)
    if(hasCompany){
      form.company = hasCompany.id
    } else {
      form.company = null
    }
  }, [form.rut])

  return (
    <>
    <SEO />
    {!isMobile ? (
        <LayoutNotMissionDesktop
          icon={Edit}
          title={title}
          subtitle={subtitle}
          handleBack={handleBack}
        >
          {step == 0 && (
            <UserInformationComponent form={form} buttonText={buttonText} handleClick={handleClick} />
          )}
          {step == 1 && (
            <MoreUserInformation form={form} buttonText={buttonText} handleClick={handleClick} />
          )}
          {step == 2 && (
            <ChooseInvestorProfile form={form} buttonText={buttonText} handleClick={handleClick} isDiscoverOn={isDiscoverOn} />
          )}
          {step == 3 && (
            <ConfirmIdentity form={form} buttonText={buttonText} handleClick={handleClick} />
          )}
        </LayoutNotMissionDesktop>
      ) : (
        <LayoutNotMissionMobile
          icon={Edit}
          title={title}
          subtitle={subtitle}
          handleBack={handleBack}
        >
          {step == 0 && (
            <UserInformationComponent form={form} buttonText={buttonText} handleClick={handleClick} />
          )}
          {step == 1 && (
            <MoreUserInformation form={form} buttonText={buttonText} handleClick={handleClick} />
          )}
          {step == 2 && (
            <ChooseInvestorProfile form={form} buttonText={buttonText} handleClick={handleClick} isDiscoverOn={isDiscoverOn} />
          )}
          {step == 3 && (
            <ConfirmIdentity form={form} buttonText={buttonText} handleClick={handleClick} />
          )}
        </LayoutNotMissionMobile>
      )}
    </>
  )
};

export default UserInformationPage;

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "100%",
    position: "relative",
    height: 529,
  },
  arrow: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  arrowIcon: {
    width: "4.2rem",
    height: "4.2rem",
    color: "#008296",
    marginRight: 90,
  },
  form: {},
  imputs: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
  },
  imputs1: {
    display: "grid",
    width: 401.5,
    gap: 23.9,
  },
  imputs2: {
    display: "grid",
    width: 401.5,
    gap: 23.9,
    marginLeft: 81.5,
  },
  boton: {
    width: 184,
    marginLeft: 693,
    marginTop: 100,
  },
}));
